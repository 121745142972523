<template>
    <v-app>
        <app-bar v-if="isAuthenticated" @toggleRail="rail = !rail" />

        <nav-bar v-if="isAuthenticatedDelayed" :rail="rail" @logout="logout" />

        <v-main>
            <v-container fluid class="animate__animated animate__fadeIn">
                <router-view v-if="isAuthenticated" />
                <LoginView v-else @login="login" />

                <v-snackbar :timeout="5000" color="primary" variant="tonal" v-model="toastShow" height="70"
                    location="bottom right" close-on-content-click class="animate__animated animate__slideInRight">
                    {{ toastMessage }}
                </v-snackbar>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import AppBar from '@/components/navigation/AppBar.vue';
import NavBar from '@/components/navigation/NavBar.vue';
import LoginView from '@/views/LoginView.vue';
import { logoutKeyCloak, getTokens } from '@/api/auth';
import eventBus from '@/config/eventBus';
import { keyCloakCodeUrl, localBaseUrl } from '@/config/globalVariables'

export default {
    components: {
        AppBar,
        NavBar,
        LoginView,
    },
    data() {
        return {
            rail: true,
            isAuthenticated: false,
            isAuthenticatedDelayed: false,
            mobile: false,
            toastShow: false,
            toastMessage: '',
        };
    },
    methods: {
        login() {
            this.isAuthenticated = true;
            this.toastShow = false;
        },
        async logout() {
            this.isAuthenticated = false;
            try {
                await logoutKeyCloak();
            } catch (error) {
                console.error('Error al cerrar sesión', error);
            }
            sessionStorage.removeItem("access_token");
            sessionStorage.removeItem("refresh_token");
            location.href = keyCloakCodeUrl;
        },
        async logoutAndNotice() {
            await this.logout();
            this.toastMessage = 'Sesión cerrada por inactividad';
            this.toastShow = true;
        },
    },
    watch: {
        isAuthenticated(value) {
            setTimeout(() => {
                this.isAuthenticatedDelayed = value;
            }, 10);
        },
    },
    async created() {
        console.log(keyCloakCodeUrl);

        if (sessionStorage.getItem("access_token")) {
            try {
                this.isAuthenticated = true;
            } catch (error) {
                if (error.response?.status === 401) {
                    sessionStorage.removeItem("access_token");
                    sessionStorage.removeItem("refresh_token");
                }
                this.isAuthenticated = false;
            }
        }
        else {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if (!urlParams.has('code')) {
                console.log("no viene el code");

                location.href = keyCloakCodeUrl;
            }
            else {
                const code = urlParams.get('code');
                console.log("viene el code: " + code);


                const response = await getTokens(code, localBaseUrl);

                console.log(response);

                sessionStorage.setItem("access_token", response.data.access_token);
                sessionStorage.setItem("refresh_token", response.data.refresh_token);

                this.isAuthenticated = true;

                this.$router.push('/');
            }
        }

        eventBus.on('logout', this.logoutAndNotice);
    },
    beforeDestroy() {
        eventBus.off('logout', this.logoutAndNotice);
    }
};
</script>

// globally
<style>
@import '@/assets/css/colors.css';
@import 'animate.css';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'DINRoundPro', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.v-main {
    background: linear-gradient(222deg,
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-bluegreendark-color), var(--background-main-secondary-opacity)),
            rgba(var(--background-tailwind-bluegreendark-color), var(--background-main-secondary-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)),
            rgba(var(--background-tailwind-dark-color), var(--background-main-opacity))) !important;
}

.v-toolbar,
.v-navigation-drawer {
    background: rgba(var(--background-tailwind-dark-color), var(--background-main-opacity)) !important;
}

.v-navigation-drawer--is-hovering.v-navigation-drawer--rail {
    background: var(--background-drawer-hovering) !important;
}

.v-card {
    background-color: rgba(var(--background-tailwind-dark-color), .1) !important;
}

.v-input--error {
    opacity: .7;
}

.dialog-card {
    background-color: rgb(var(--v-theme-surface)) !important;
    opacity: 100% !important;
}
</style>