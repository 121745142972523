import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/onboarding-report',
        name: 'onboarding-report',
        component: () => import(/* webpackChunkName: "onboarding-report" */ '../views/OnboardingReportView.vue')
    },
    {
        path: '/onboarding-report-atc',
        name: 'onboarding-report-atc',
        component: () => import(/* webpackChunkName: "onboarding-report" */ '../views/OnboardingReportATCView.vue')
    },
    {
        path: '/onboarding-graficos',
        name: 'onboarding-graficos',
        component: () => import(/* webpackChunkName: "onboarding-report" */ '../views/OnboardingGraficosView.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
